h1,.h1-responsive {
    font-size: 150%;
    font-family: 'DIN Pro Bold';
    -webkit-text-stroke-width: 0;
}

h2,.h2-responsive {
    font-size: 140%;
    font-family: 'DIN Pro Bold';
    -webkit-text-stroke-width: 0;
    margin-top: 1rem;
}

h3,.h3-responsive {
    font-size: 130%;
    font-family: 'DIN Pro Bold';
    -webkit-text-stroke-width: 0;
    margin-top: 1rem;
}

h4,.h4-responsive {
    font-size: 120%;
    font-family: 'DIN Pro Bold';
    -webkit-text-stroke-width: 0;
    margin-top: 1rem;
}

h5,.h5-responsive {
    font-size: 110%;
    font-family: 'DIN Pro Bold';
    -webkit-text-stroke-width: 0;
    margin-top: 1rem;
}

@media(max-width: 440px) {
  h1,.h1-responsive {
      font-size: 140%;
      font-family: 'DIN Pro Bold';
      -webkit-text-stroke-width: 0;
  }

  h2,.h2-responsive {
      font-size: 130%;
      font-family: 'DIN Pro Bold';
      -webkit-text-stroke-width: 0;
      margin-top: 1rem;
  }

  h3,.h3-responsive {
      font-size: 120%;
      font-family: 'DIN Pro Bold';
      -webkit-text-stroke-width: 0;
      margin-top: 1rem;
  }

  h4,.h4-responsive {
      font-size: 115%;
      font-family: 'DIN Pro Bold';
      -webkit-text-stroke-width: 0;
      margin-top: 1rem;
  }

  h5,.h5-responsive {
      font-size: 105%;
      font-family: 'DIN Pro Bold';
      -webkit-text-stroke-width: 0;
      margin-top: 1rem;
  }
}

.page-heading {
  border-bottom: 1px solid #e9ecef;
  padding-bottom: .5rem;
  margin-bottom: 20px;
}
