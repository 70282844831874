@font-face {font-family: "DIN Pro";
  src: url("../../../fonts/din-pro-regular/15a478cc0eb1d84f4077a2a9d28df0b2.eot"); /* IE9*/
  src: url("../../../fonts/din-pro-regular/15a478cc0eb1d84f4077a2a9d28df0b2.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../../fonts/din-pro-regular/15a478cc0eb1d84f4077a2a9d28df0b2.woff2") format("woff2"), /* chrome、firefox */
  url("../../../fonts/din-pro-regular/15a478cc0eb1d84f4077a2a9d28df0b2.woff") format("woff"), /* chrome、firefox */
  url("../../../fonts/din-pro-regular/15a478cc0eb1d84f4077a2a9d28df0b2.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("../../../fonts/din-pro-regular/15a478cc0eb1d84f4077a2a9d28df0b2.svg#DIN Pro") format("svg"); /* iOS 4.1- */
}

@font-face {font-family: "DIN Pro Medium";
  src: url("../../../fonts/din-pro-medium/5fd82f3bb8dcfc3ce611fab541b92241.eot"); /* IE9*/
  src: url("../../../fonts/din-pro-medium/5fd82f3bb8dcfc3ce611fab541b92241.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../../fonts/din-pro-medium/5fd82f3bb8dcfc3ce611fab541b92241.woff2") format("woff2"), /* chrome、firefox */
  url("../../../fonts/din-pro-medium/5fd82f3bb8dcfc3ce611fab541b92241.woff") format("woff"), /* chrome、firefox */
  url("../../../fonts/din-pro-medium/5fd82f3bb8dcfc3ce611fab541b92241.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("../../../fonts/din-pro-medium/5fd82f3bb8dcfc3ce611fab541b92241.svg#DIN Pro Medium") format("svg"); /* iOS 4.1- */
}

@font-face {font-family: "DIN Pro Bold";
  src: url("../../../fonts/din-pro-bold/03f16d9e32dbe7da6c831a87bd666b3e.eot"); /* IE9*/
  src: url("../../../fonts/din-pro-bold/03f16d9e32dbe7da6c831a87bd666b3e.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../../fonts/din-pro-bold/03f16d9e32dbe7da6c831a87bd666b3e.woff2") format("woff2"), /* chrome、firefox */
  url("../../../fonts/din-pro-bold/03f16d9e32dbe7da6c831a87bd666b3e.woff") format("woff"), /* chrome、firefox */
  url("../../../fonts/din-pro-bold/03f16d9e32dbe7da6c831a87bd666b3e.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("../../../fonts/din-pro-bold/03f16d9e32dbe7da6c831a87bd666b3e.svg#DIN Pro Bold") format("svg"); /* iOS 4.1- */
}

html {
  font-size: 14px;
  scroll-behavior: smooth;
}

body {
  font-family: 'DIN Pro';
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  background: #e9ebee;
  scroll-behavior: smooth;
  font-size: 1.0825rem;
}

.w-100 {
  width: 100%;
}

@media	only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (-o-min-device-pixel-ratio: 18/10),
	only screen and (min-resolution: 170dpi)
{
  /* Even higher DPI devices */
  body {
    font-size: 100%;
  }
}

@-webkit-keyframes flash {
	0% { opacity: 1; }
	50% { opacity: .5; }
	100% { opacity: 1; }
}
@keyframes flash {
	0% { opacity: 1; }
	50% { opacity: .5; }
	100% { opacity: 1; }
}

.box-share {
  padding: 1.45rem 0;
  border-top: 1px solid #ececec;
  margin-top: 2rem;
  text-align: center;
  span {
    display: block;
    padding-bottom: 6px;
    font-size: .875em;
    color: #4c4c4c;
  }
    button {
      background: transparent;
      margin-right: 8px;
      width: 34px;
      border-radius: 50%;
      font-size: 1.4em;
      color: $main-color;
      margin-bottom: 8px;
      transition: .25s;
      border: none;
      &:hover {
        background: $main-color;
        color: #ffffff;
      }
    }

    button {
      &[data-type="fb"] {
        background: transparent;
        color: #4064ad;
        &:hover {
          background: #4064ad;
          color: $white;
        }
      }

      &[data-type="twitter"] {
        background: transparent;
        color: #1da1f2;
        &:hover {
          background: #1da1f2;
          color: $white;
        }
      }

      &[data-type="googlebookmarks"] {
        background: transparent;
        color: #ea4335;
        &:hover {
          background: #ea4335;
          color: $white;
        }
      }

      &[data-type="pinterest"] {
        background: transparent;
        color: #e60023;
        &:hover {
          background: #e60023;
          color: $white;
        }
      }

      &[data-type="linkedin"] {
        background: transparent;
        color: #0077b5;
        &:hover {
          background: #0077b5;
          color: $white;
        }
      }

      &[data-type="skype"] {
        background: transparent;
        color: #0078CA;
        &:hover {
          background: #0078CA;
          color: $white;
        }
      }

      &[data-type="whatsapp"] {
        background: transparent;
        color: #00e676;
        &:hover {
          background: #00e676;
          color: $white;
        }
      }

      &[data-type="email"] {
        background: transparent;
        color: #000000;
        &:hover {
          background: #000000;
          color: $white;
        }
      }

    }
}

main {
  padding: 30px;
  background: $white;
  margin-top: 1rem;
  min-height: 400px;
  border-radius: 3px;
}


@media(min-width:1024px) {
  .video-items {
    max-height: 1058px;
    overflow-y: auto;
    .video-item {
      padding-right: 1rem;
    }
  }
}

.video-items {
  .video-item {
    margin-bottom: 1rem;
    a {
      display: flex;
      height: 60px;
      overflow: hidden;
      figure {
        flex-basis: 110px;
        flex-shrink: 0;
        height: 60px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        margin-right: 8px;
      }
      .title {
        @include ellips(2);
        overflow: hidden;
        margin: auto auto auto 0;
      }
    }
  }
}

.fr-draggable {
  height: auto!important;
    margin-bottom: 1.25rem;
    margin-top: 1rem;
}

a {
  color: $gray-800;
  &:hover {
    color: #000000;
    text-decoration: none;
  }
  &.light {
    color: #545454;
    &:hover {
      color: #000000;
    }
  }
  &.button--dark {
    &:hover {
      color: #ffffff;
    }
  }
}

p {
  margin-bottom: 1.25rem;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

section {
  margin-bottom: 2rem;
}

img {
  max-width: 100%;
  &.lazy {
    animation: flash 2s infinite;
    &.loaded {
      animation: none;
    }
  }

  &.swiper-lazy {
    animation: flash 2s infinite;
    &.swiper-lazy-loaded {
      animation: none;
    }
  }
}

button:focus, input:focus {
  outline: none;
}

.breadcrumb {
  background: $white;
  border: 1px solid #dddfe2;
  border-radius: 3px;
  padding: 1rem;
  a {
    color: #34434a;
    &:hover {
      color: #000;
    }
  }
}

.fancy-video {
  position: relative;
  display: block;
  &:before {
    content: "";
    background: rgba(0,0,0,.05);
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    transition: .25s;
  }
  &:after {
    content: "\f144";
    font-family: FontAwesome;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #c8312d;
    text-align: center;
    font-size: 4rem;
    transition: .25s;
  }
  &:hover {
    &:before {
      background: rgba(0,0,0,0);
    }
  }
}

.gmap {
  width: 100%;
  height: auto;
  min-height: 360px;
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
}

.text-center {
  text-align: center;
}

.scrollable-mark {
  position: absolute;
  right: .5rem;
  top: 50%;
  transform: translateY(-50%);
  color: #0d5451;
  -webkit-animation: linear infinite;
  -webkit-animation-name: run;
  -webkit-animation-duration: 1s;
  font-size: 1.6em;
}


@-webkit-keyframes run {
    0% { right: 1.4rem; opacity: 1;}
    50%{ right : .5rem; opacity: 0;}
    100%{ right: 1.4rem; opacity: 1;}
}

/*slider*/
.gallery-top {
   height: 80%;
   width: 100%;
   max-height: 430px;
   background: #f8f9fa;
 }
 .gallery-thumbs {
   height: 20%;
   box-sizing: border-box;
   padding: .5rem 0 .5rem 0!important;
   .swiper-slide {
     padding: 2px 0;
     height: 100%;
     filter:grayscale(1);
   }
   .swiper-slide-thumb-active {
     filter: grayscale(0);
   }
   img {
     cursor: pointer;
     color: #d6d6d6;
     background: #d6d6d6;
   }
 }

.gallery-top {
  .swiper-slide-prev, .swiper-slide-next {
    filter: blur(1px) opacity(.5);
   -webkit-filter: blur(1px) opacity(.5);
   -moz-filter: blur(1px) opacity(.5);
   -ms-filter: blur(1px) opacity(.5);
   -o-filter: blur(1px) opacity(.5);
 }
}

/* gallery */
.gallery {
  padding-top: .5rem;
  img {
    filter: brightness(.9);
    transition: .25s linear;
    &:hover {
      filter: brightness(1);
    }
  }
  div {
    padding-left: 0;
    padding-right: 12px;
    padding-bottom: 12px;
  }
  &.press {
    figure {
      height: 160px;
    }
  }
}

/* footer */
footer {
  background-color: #222;
  padding: 1rem;
  color: #fff;
  margin-top: 2rem;
  .social-media-item {
    font-size: 1.25em;
  }
  span {
    &.h6-responsive {
      display: block;
      margin-bottom: 1rem;
    }
  }
  p {
    margin-bottom: .5rem;
    font-size: 13px;
  }
  ul {
    li {
      display: inline-block;
      padding-left: 6px;
    }
    list-style-type: none;
    padding-left: 0;
    li,a {
      color: #d2d2d2;
      &:hover {
        color: #fff;
      }
    }
  }
  input[type=text], button {
    padding: .5rem;
    font-size: 14px;
    border: 1px solid $main-color;
    border-radius: 3px;
    background: transparent;
    color: #fff;
  }

  button {
    &:hover {
      background: $main-color;
    }
  }

  label {
    font-size: 12px;
  }

  a {
    color: #d2d2d2;
    &:hover {
      color: $white;
    }
  }

  .privacy {
    font-size: 13px;
    text-decoration: underline;
  }

  .footer-logo {
    opacity: .9;
    transition: 600ms;
    &:hover {
      opacity: 1;
    }
  }

}

.socials {
  text-align: right;
  li {
    padding-right: .5rem;
    display: inline-block;
    a {
      font-size: 18px;
    }
  }
}

.header-flag {
  max-width: 50px;
}

/* sidebar */
.sidebar {
  z-index: 9;
  overflow-y: auto;
  &.sticky {
    overflow-y: inherit;
  }
  legend {
    font-size: 18px;
    font-weight: 600;
    border-bottom: 4px solid #dcdcdc;
    padding-bottom: 1rem;
  }

  .treeview {
    a span:before {
        content: "\f196";
        font-family: FontAwesome;
        padding-right: 6px;
        color: $main-color;
    }
    &.active {
      a span:before {
          content: "\f147";
          font-family: FontAwesome;
          padding-right: 6px;
          color: $main-color;
      }
    }
  }

  .sidebar-menu > .treeview > .treeview-menu {
    border-left: 1px solid #f5f5f5;
    background: #fbfbfb;
  }

  .sidebar-menu {
    .treeview {
      .menu-open {
        a {
          span {
            &:before {
              content: "\f196";
            }
          }
        }
        .treeview.active {
          a {
            span {
              &:before {
                content: "\f147";
              }
            }
          }
        }
      }
    }
    list-style: none;
    margin: 0;
    padding: 0;
    &.sticky {
      position: sticky;
      top: 70px;
    }
  }
    .sidebar-menu > li {
      &:not(.header) {
          border-bottom: 1px solid #f3f3f3;
      }
      position: relative;
      margin: 0;
      padding: 0; }
      .sidebar-menu > li > a {
        span {
          color: $main-color;
        }
        &.active {
          border-left: 3px solid $main-color;
          background: #f9f9f9;
        }
        padding: 8px 5px 8px 15px;
        display: block;
        border-left: 3px solid transparent;
        color: $black; }
        .sidebar-menu > li > a > .fa {
          width: 20px; }
      .sidebar-menu > li:hover > a:not(.active), .sidebar-menu > li.active > a {
        color: $gray-700;
        border-left-color: $gray-200; }
      .sidebar-menu > li > .treeview-menu {
        margin:0;}
      .sidebar-menu > li .label,
      .sidebar-menu > li .badge {
        margin-top: 3px;
        margin-right: 5px; }
    .sidebar-menu li.header {
      font-family: 'DIN Pro Medium';
      padding: .60rem;
      color: #000000;

      border-bottom: 1px solid #e9ecef;
       }
    .sidebar-menu li > a > .fa-angle-left {
      width: auto;
      height: auto;
      padding: 0;
      margin-right: 10px;
      margin-top: 3px; }
    .sidebar-menu li.active > a > .fa-angle-left {
      transform: rotate(-90deg); }
    .sidebar-menu li.active > .treeview-menu {
      display: block; }
    .sidebar-menu a {
      color: #b8c7ce;
      text-decoration: none; }
    .sidebar-menu .treeview-menu {
      display: none;
      list-style: none;
      margin: 0;
      padding-left:0; }
      .sidebar-menu .treeview-menu .treeview-menu {
        padding-left: 20px; }
      .sidebar-menu .treeview-menu > li {
        margin: 0; }
        .sidebar-menu .treeview-menu > li > a {
          &:before {
            content: "\f0da";
            font-family: FontAwesome;
            margin-right: 6px;
          }
          padding: 8px 5px 8px 15px;
          display: block;
          color: $gray-600; }
          .sidebar-menu .treeview-menu > li > a > .fa {
            width: 20px; }
          .sidebar-menu .treeview-menu > li > a > .fa-angle-left,
          .sidebar-menu .treeview-menu > li > a > .fa-angle-down {
            width: auto; }
        .sidebar-menu .treeview-menu > li.active > a, .sidebar-menu .treeview-menu > li > a:hover {
          color: #495057;
          }
}

.breadcrumb-item+.breadcrumb-item::before {
    content: "\f054";
    font-family: FontAwesome;
    font-size: 10px;
}

.sidebar-on {
  &:after {
    content: "";
    background: rgba(0,0,0,.25);
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
}

//cards

.card {
  border: 1px solid rgba(179, 179, 179, 0.125);
  margin-top: 2rem;
  margin-bottom: 2rem;
  .card-header {
    border-bottom: 1px solid rgba(179, 179, 179, 0.125);
    font-family: 'DIN Pro Medium';
  }
}
.card-footer {
  font-size: 13px;
  padding: .5rem 1.25rem;
  border-top: 1px solid rgba(179, 179, 179, 0.125);
  a {
    color: $main-color;
  }
}

.btn {
  &.btn-primary {
    background: $main-color;
    padding: 8px;
    color: #ffffff;
    font-size: .875em;
    border-radius: 2px;
    display: inline;
    &:hover {
      background: darken($main-color, 4%);
    }
  }
}

.button {
  border-radius: 2px;
  padding: 6px 12px 6px 12px;
  font-size: .875em;
  transition: .25s linear;
  margin-right: 2px;
  margin-left: 2px;
  &--dark {
    @extend .button;
    background: #000;
    border: 1px solid #333;
    color: #fff;
    &:hover {
      background: #1f1f1f;
    }
  }

  &--light {
    @extend .button;
    background-color: #f5f6f7;
    border: 1px solid #dadde1;
    color: $black;
    &:hover {
      background: #e0e0e0;
    }
  }

}

//table
.table td, .table th {
  padding: .5rem;
  vertical-align: top;
  border-top: 1px solid #efefef;
  font-size: 13px;
}

//badge
.badge-primary {
    padding: 4px 10px;
    background: #f7f7f7;
    border-radius: 4px;
    color: #000;

}

//alert
.alert {
  padding: 1rem;
  border-radius: 4px;
  &--info {
    @extend .alert;
    background: #e9f5ff;
    color: #354552;
    border: 1px solid #c1cad2;
    &:before {
      content: "\f05a";
      font-family: FontAwesome;
      padding: .5rem;
    }
  }

  &--danger {
    @extend .alert;
    background: #af3418;
    color: #ffd2c8;
    border: 1px solid #882c17;
    &:before {
      content: "\f00d";
      font-family: FontAwesome;
      padding: .5rem;
    }
  }

  &--light {
    @extend .alert;
    background: #fdfdfd;
    color: #354552;
    border: 1px solid #f3f3f3;
  }
}

.select2-container--default .select2-selection--single {
  height: calc(1.5em + .75rem + 4px);
  padding: .5rem .5rem;
  line-height: 1.5;
  border: 1px solid #ced4da;
  font-size: .85rem;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 1.5;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 5px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $main-color;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #dedede;
}

.custom-control-label {
  &:before, &:after {
    top: 2px;
  }
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: $main-color;
    background-color: $main-color;
}

label {
  color: #868686;
  margin-bottom: 4px;
  font-size: 86%;
}

.academic-search {
  background-color: transparent;
  padding: 1.6rem 1rem;
  border: 1px solid #eeeeee;
  border-bottom: 2px solid $main-color;
  border-radius: 0;
  &:focus {
    border-color: #6cbdba;
    box-shadow: none;
  }
}

.academic-staff {
  .academic-staff-item {
    display: flex;
    position: relative;
    margin-bottom: 0;
    padding: 12px;
    transition: .25s;
    border-bottom: 1px solid #e4edf7;
    figure {
      width: 90px;
      height: 80px;
      min-width: 90px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: auto;
      margin-bottom: auto;
    }

    .meta {
      padding-left: 18px;
      margin-top: auto;
      margin-bottom: auto;
    }

    legend {
      font-size: 1em;
      margin-bottom: 0;
      font-family: 'DIN Pro Medium';
    }

    a {
      font-size: .945em;
      padding-right: .75rem;
      color: #1e7571;
      &:hover {
        color: #000;
      }
    }

    p {
      color: #616161;
      margin-bottom: 2px;
      font-size: .95em;
      max-width: 600px;
    }

    span {
      font-size: .95em;
    }

    &:hover {
      background: #f7f7f7;
    }
    &:last-child {
      border-bottom: none;
    }
    .cv-buttons {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

}

.events-wrapper, .principles-wrapper {
  background: #ffd504;
}

/*buttons*/
.button-primary {
  padding: 4px 14px;
  background: $main-color;
  border: 1px solid darken($main-color, 10%);
  border-radius: 4px;
  color: #fff;
  margin-right: .4rem;
  font-size: 13px;
  &.light {
    background-color: #f5f6f7;
    border: 1px solid #dadde1;
    color: #4a4a4a;
    &:hover {
      background-color: #ebedf0;
    }
  }
  &.dark {
    background: #333;
    border: 1px solid #000;
    color: #fff;
    &:hover {
      background: #000;
    }
  }
  &.small {
    padding: 4px 8px;
    font-size: 13px;
  }
  &:hover {
    background: darken($main-color, 5%);
  }
}

.btn-uskudar {
  @extend .button-primary;
  @extend .button-primary.light;
  @extend .button-primary.small;
  display: inline-block;
  margin-bottom: .5rem;
}

a {
  &.btn-uskudar {
    &:hover {
      color: #ffffff;
    }
  }
}

.contact-box {
  .contact-box-item {
    a {
      color: #354552;
      &:hover {
        color: $black;
      }
    }
  }
}

.rd-navbar-fixed {
  .rd-navbar-collapse-toggle {
    &.sidemenu {
      right: 1.5rem;
      z-index: 15;
    }
  }
}

.faaliyet {
  a {

    color: #255d73;
    transition: .25s;
    &:hover {
      color: $black;
    }
  }
}

b, strong {
    font-family: 'DIN Pro Bold';
    -webkit-text-stroke-width: 0;
}

.svg-container {
  width: 100%;
}

.circle {
  height: 114px;
  width: 114px;
  display: inline-block;
  position: relative;

  .small {
    font-size: .975em;
    text-anchor: middle;
    font-family: 'DIN Pro Medium';
  }

  .big {
    text-anchor: middle;
    font-family: 'DIN Pro Bold';
    font-size: 1.6em;
  }

  .background {
    fill: transparent;
    stroke: #dedede;
    transition: all 200ms ease;
  }

  .foreground {
    fill: transparent;
    stroke-dasharray: 377;
    stroke-dashoffset: 377;
    stroke: $main-color;
    transform-origin: 50% 50%;
    transform: rotate(-270deg);
    transition: all 800ms ease;
  }

  .line {
    stroke-width: 2;
    stroke: #1abc9c;
    transform-origin: 50% 50%;
    transition: all 600ms ease;
  }

  .line2 {
    transform: rotate(-90deg);
  }

  &:hover {
    cursor: pointer;

    .background {
      stroke: transparent;
    }

    .foreground {
      stroke-dashoffset: 0;
      transform: rotate(-90deg);
    }

    .line {
      stroke: #EE3769;
    }

    .line {
      transform: rotate(180deg);
    }

    .line2 {
      transform: rotate(0);
    }
  }
  &:nth-child(1) {
    .background {
      stroke: #7ccfbd;
    }
    .foreground {
      stroke: #dedede;
    }
  }
  &:nth-child(2) {
    .background {
      stroke: #7cbacf;
    }
    .foreground {
      stroke: #dedede;
    }
  }
  &:nth-child(3) {
    .background {
      stroke: #8acf7c;
    }
    .foreground {
      stroke: #dedede;
    }
  }
  &:nth-child(4) {
    .background {
      stroke: #becf7c;
    }
    .foreground {
      stroke: #dedede;
    }
  }
  &:nth-child(5) {
    .background {
      stroke: #cfb27c;
    }
    .foreground {
      stroke: #dedede;
    }
  }
  &:nth-child(6) {
    .background {
      stroke: #cf9c7c;
    }
    .foreground {
      stroke: #dedede;
    }
  }
  &:nth-child(7) {
    .background {
      stroke: #ffd504;
    }
    .foreground {
      stroke: #dedede;
    }
  }
}

.swiper-button-prev {
  left: 20px;
}

.swiper-button-next {
  right: 20px;
}

g {
    cursor: initial;
}

hr {
      border-top: 1px solid #fff;
}


.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
      .list-group-item {
      position: relative;
      display: block;
      padding: .75rem 1.25rem;
      margin-bottom: -1px;
      background-color: #fff;
      border: 1px solid rgba(0,0,0,.125);
      &:first-child {
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
      }
      &:last-child {
        margin-bottom: 0;
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: .25rem;
      }
  }
}

.left-menu {
  a{
    color: #212529;
text-decoration: none;
  }
  ul {
      list-style: none;
      padding: 0;
      margin-left: 5px;
      li {
        border: solid 1px #535353;
        font-size: 20pt;
        padding: 5px 0;
        text-align: center;
        width: 55px;
        float: left;
        margin: 10px 5px;
        &.disabled {
          color: #c7c7c7;
        }
      }
  }
}

.fb-180 {
  flex-basis: 180px;
}

.br-none {
  border-radius: 0;
}

.pagination-button {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: $gray-700;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination {
  li {
    &.current {
      span {
        background: $main-color;
        color: #ffffff;
      }
    }
    a,span {
      @extend .pagination-button;
    }
    a:hover {
          background: #f3f3f3;
    }
  }
}

.pagestr {
  @extend .pagination-button;
}

.institues {
  .card {
    max-width: 400px;
    min-width: 380px;
  }
  figure {
    height: 60px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    margin-bottom: 1.6rem;
    margin-top: 1.6rem;
  }
}


.well.enstitu-form {
    padding: 13px;
    img {
      max-width: 740px;
    margin-bottom: 1rem;
    }
}
.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}
.enstitu-form {
    background: #141820 url(https://uskudar.edu.tr/assets/img/enstitu-form-bg.png?1) no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .enstitu-form .input-group {
    margin-bottom: 10px;
}
.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 6px 0 0 6px;
}

.btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:not(:first-child), .input-group>.custom-file:not(:first-child) .custom-file-label, .input-group>.custom-select:not(:first-child), .input-group>.form-control:not(:first-child), nav form .input-group>.form-control:not(:first-child):focus {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group>.custom-select:not(:first-child), .input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group>.custom-file, .input-group>.custom-select, .input-group>.form-control, .input-group>.form-control-plaintext, nav form .input-group>.form-control:focus {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}
.input-group>.custom-file, .input-group>.custom-select, .input-group>.form-control {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.fw-600 {
  font-family: 'DIN Pro Medium';
}

.no {
  display: none;
}

.border-bold {
    border: 2px solid #000000;
}

.homepage-notification {
  padding: 1rem;
  border: 2px solid #19a59f;
}

article {
  color: #000000;
  h1 {
    font-size: 1.825rem;
    line-height: 2.6rem;
  }

  p {
    font-size: 1.125rem;
    line-height: 1.875rem;
  }
   ul li, ol li {
     font-size: 1.125rem;
     line-height: 1.875rem;
   }

   legend {
     font-size: 1.57143rem;
      line-height: 2.14286rem;
      font-family: 'DIN Pro Bold';
   }
}

@media(max-width:641px) {
  article {
    padding: 1rem;
    h1 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
}

.qm-custom-item {
  color: #c8312d!important;
}

.author {
    display: flex;
    .image-wrapper {
    flex-basis: 76px;
    flex-shrink: 0;
        img {
        max-width: 100%;
        border-radius: 4px;
    }
}
  .text-wrapper {
      padding: 2px 12px;
    span {
      color: #434344;
  }
  p {
      margin-bottom: 0;
      font-family: 'DIN Pro Medium';
      font-size: 1.175rem;
  }
  }
}

.online-support {
  margin-top: 3rem;
}
.online-support form label {
  color:#101010;
}

.online-support .support-image {
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.homepage-grid {
  position: relative;
  margin-bottom: 15px;
  margin-top: 15px;
  transition: all 200ms;
  min-height: 180px;
  p {
    position: absolute;
    color: #ffffff;
    bottom: 0;
    left: 1rem;
    font-size: 1.125em;
    text-shadow: 2px 2px 4px #000000;
  }
  &:before {
    content: "";
    position: absolute;
    display: block;
    background-image: linear-gradient(0deg, black, transparent);
    left: 0;
    right: 0;
    height: 100px;
    bottom: 0;
    opacity: 0.5;
    transition: 200ms;
  }
  a {
    color : #ffffff;
  }
}

.rd-navbar-brand {
  max-width: 180px;
}

.rd-navbar-fixed .rd-navbar-brand {
  max-width: 130px;
}
