@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

@mixin transform($trfm) {
  -webkit-transform: $trfm;
  transform: $trfm;
}

@mixin ellips($rows) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $rows;
  -webkit-box-orient: vertical;
}
