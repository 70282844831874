/* responsive */

.slider {
  max-width: 1920px;
}

@media(min-width:1366px) {
  .has-caret {
    &:after {
      margin-left: 6px;
      color: #fff;
      font-size: .75em;
      content: "\f0d7";
      font-family: FontAwesome;
    }
  }
}

@media(max-width:1366px) {
  .gallery-top {
    max-height: 420px;
  }
}

@media(min-width:2500px) {
  .collobration {
    max-width: 1640px;
    margin-left: auto;
    margin-right: auto;
  }

  .principles {
    background: transparent;
  }

  .slider {
    max-width: 1640px;
  }
}

@media(max-width:1280px) {
  .gallery-top {
    max-height: 390px;
  }
}

@media(max-width:1200px) {

  body {
    margin-top: 80px;
  }
}

@media(max-width:1024px) {

  .gallery-top {
    max-height: 296px;
  }

  .sidebar {
    position: fixed;
    right: 0;
    max-width: 300px;
    bottom: 0;
    top: 70px;
    background: #ffffff;
    padding-top: 2rem;
    border-left: 1px solid #d0d0d0;
    transform: translateX(300px);
    transition: .25s;
    &.opened {
      transform: translateX(0);
    }
  }

  body {
    &.home {
      margin-top: 56px;
    }
  }

  .academic-card {
    .academic-card-meta {
      width: 440px;
    }
  }

  .homepage-buttons {
    padding: 2rem 4rem;
  }

  .homepage-menu-wrapper {
    .homepage-menu {
      top: 0;
      overflow: scroll;
      scroll-behavior: smooth;
      justify-content: flex-start;

      .homepage-menu-item {
        margin-right: 1rem;

        a {
          white-space: nowrap;
          font-size: 13px;
          padding: 0.5rem;
        }
      }

      .homepage-menu-item:first-of-type {
        a {
          white-space: pre-wrap;
        }
      }
    }
  }

  body {
    margin-top: 80px;
  }

  .gallery-thumbs {
    max-width: 100%!important;
  }

  .rd-navbar-fixed .rd-navbar-panel-canvas {
    background: #f1f1f1;
  }

  .campus {
    img {
      max-height: 58px;
    }
  }

  .video-grid {
    .video-grid-item {
      .btn-watch {
        opacity: 1;
        bottom: .5rem;
      }
    }
  }
}


@media(min-width:900px) {
  .scrollable-mark {
    display: none;
  }
}


@media(min-width:768px) and (max-width:1024px) {
  .sidebar {
    max-width: 340px;
    transform: translateX(340px);
  }
}

@media(max-width:800px) {
  .collobration {
    height: 300px;

    .collobration-list {
      .collobration-list-item {
        margin: 0 1rem 1.5rem;
        max-width: 180px;
      }
    }
  }

  .homepage-buttons {
    padding: 1.5rem;

    .homepage-buttons-item {
      margin-bottom: 0;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        margin-bottom: 0;
      }
    }
  }

  .homepage-menu-wrapper {
    .homepage-menu {
      display: inherit;

      .homepage-menu-item:first-of-type {
        max-width: 120px;
      }
    }
  }

}

@media(max-width:768px) {

  .gallery-top {
    max-height: 230px;
  }

  .box-list {
    .box-list-item {
      .number {
        display: none;
      }
    }
  }
  .campus {
    img {
      max-height: 100px;
    }
  }

  .box-list {
    .box-item-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
    .box-list-item {
      background: #f5f5f5;
      border: none;
    }
  }

  .events {
    padding-left: 0;
    padding-right: 0;
  }

  .campus {
    padding: 0;
  }

  .academic-card {
    min-height: unset;
    position: relative;
    .academic-card-inner {
      background: #ffffff;
      display: block;
      position: static;
      top: unset;
      transform: unset;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    figure {
      margin-right: auto;
      margin-left: unset;
      margin-bottom: 1rem;
    }
    .academic-card-meta {
      margin-bottom: 1rem;
      width: auto;
      height: auto;
    }
    .academic-card-socials {
      margin-bottom: 1rem;
      ul {
        padding-left: 0;
      }
    }
    .orcid {
      position: static;
      text-align: center;
    }
  }

  .video-items {
    .video-item {
      margin-bottom: 4px;
      a {
        display: block;
        height: 160px;
        figure {
          height: 100px;
          margin-bottom: 4px;
          margin-right: 0;

        }
      }
    }
  }
}

@media(max-width:641px) {

  .gallery-top {
    max-height: 130px;
  }

.container {
  padding-left: 0;
  padding-right: 0;
}

  iframe {
    max-width: 100%;
    max-height: 190px;
}

  .socials {
    text-align: center;
  }

  footer {
    p {
      text-align: center;
    }
  }

  .box-list {
    .box-list-item {
      ul {
        max-height: 230px;
      }
    }
  }

  .academic-card {
    figure {
      margin-left: auto;
    }
  }

  .box-list {
    .box-list-item {
      ul {
        margin-top: 1rem;

        li {
          padding-bottom: 0.4rem;
        }
      }
    }
  }

  .principles {
    .principles-list {
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      padding-top: 4rem;

      > span {
        position: absolute;
        top: 1.6rem;
      }

      .principles-list-item {
        width: 50%;
        text-align: center;
      }
    }
  }

  .collobration {

    .collobration-list {
      .collobration-list-item {
        max-width: 140px;
      }
    }
  }


}

@media(max-width:440px) {

  .homepage-buttons {
    .homepage-buttons-item {
      span {
        font-size: 12px;
        max-width: 86px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  footer {
    padding: 1rem;
  }

  .breadcrumb {
    .breadcrumb-item {
      width: 100%;
      margin-bottom: 4px;
    }
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
  }

  main {
    margin-top: 0;
    padding: 1.4rem 1.6rem;
    .col-lg-8 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .video-grid {
    .video-grid-item {
      padding-left: 0;
      padding-right: 0;
    }
  }

  body {
    margin-top: 80px;
    &.home {
      margin-top: 70px;
    }
  }

  .sp-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .academic-staff {
    .academic-staff-item {
      figure {
        margin-top: 0;
      }
    }
  }
}

@media(max-width:330px) {
  .rd-navbar-fixed .rd-navbar-collapse-toggle.sidemenu {
    max-width: 100px;
  }
}
